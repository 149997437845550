import Vue from 'vue';
import Vuex from 'vuex';
import environments from './modules/environments';
import aws from './modules/aws';
import versions from './modules/versions';
import mhrequests from './modules/mhrequests';
import customers from './modules/customers';
import cloud from './modules/cloud';
import settings from './modules/settings';
import faq from './modules/faq';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        environments,
        aws,
        versions,
        mhrequests,
        customers,
        cloud,
        settings,
        faq
    }
});
