import { Commit } from 'vuex';
import manager from '../../lib/manager';
import Constants from '@/constants';

import type {
    CloudStackBoxCounts,
    CloudStackBoxCountsWithLetter,
    CloudEnvironment,
    CloudConfigsByEnvironment
} from '../types';

type State = {
    activeStackLetter: string;
    aStackBoxCounts: CloudStackBoxCounts;
    bStackBoxCounts: CloudStackBoxCounts;
    configByEnvironment: CloudConfigsByEnvironment;
    secrets: { [key: string]: string | number }[];
};

const state: State = {
        activeStackLetter: '',
        aStackBoxCounts: {
            web: 0,
            worker: 0
        } as CloudStackBoxCounts,
        bStackBoxCounts: {
            web: 0,
            worker: 0
        } as CloudStackBoxCounts,
        configByEnvironment: {} as CloudConfigsByEnvironment,
        secrets: []
    },
    getters = {
        getBoxCountForStackLetter: (state: State) => (stackLetter: string) => {
            if (!stackLetter) {
                console.error(`getBoxCountForStackLetter() was called with no stackLetter, which is invalid.`)
            }

            switch (stackLetter.toLowerCase()) {
                case 'a':
                    return state.aStackBoxCounts;
                case 'b':
                    return state.bStackBoxCounts;
                default:
                    console.error(
                        `Stack letter ${stackLetter} can only be 'a' or 'b'`
                    );
            }
        }
    },
    actions = {
        getActiveStack({ commit }: { commit: Commit }) {
            return manager.getCloudActiveStack().then((result: string) => {
                commit('setActiveStack', result);
            });
        },
        getBoxCountsFromAws(
            { commit }: { commit: Commit },
            stackLetter: string
        ) {
            return manager
                .getBoxCountsForStack(stackLetter)
                .then((result: CloudStackBoxCounts) => {
                    commit('setBoxCountsForStackLetter', {
                        stackLetter: stackLetter,
                        boxCounts: result
                    } as CloudStackBoxCountsWithLetter);
                });
        },
        getAllConfigs({ commit }: { commit: Commit }) {
            const configPromises:Promise<void>[] = [];

            Constants.cloudEnvironments.forEach( (envName) => {
                const configPromise = manager
                    .getCloudConfig(envName as CloudEnvironment)
                    .then((result: { [key: string]: string | number }[]) => {
                        commit('setConfigForEnvironment', {
                            env: envName,
                            config: result
                        });
                    });

                configPromises.push(configPromise)
            })

            return Promise.all(configPromises);
        },
        getSecrets({ commit }: { commit: Commit }) {
            return manager
                .getCloudSecrets()
                .then((result: { [key: string]: string | number }[]) => {
                    commit('setSecrets', result);
                });
        },
        updateSetting(
            {}: { commit: Commit },
            setting: { key: string; value: string },
            env: CloudEnvironment = 'Prod'
        ) {
            return manager.updateCloudConfigSetting(setting, env);
        },
        addSetting(
            {}: { commit: Commit },
            setting: { key: string; value: string },
            env: CloudEnvironment = 'Prod'
        ) {
            return manager.addCloudConfigSetting(setting, env);
        }
    },
    mutations = {
        setActiveStack(state: State, activeStackLetter: string) {
            if(!activeStackLetter) {
                activeStackLetter = "NONE";
            }

            switch (activeStackLetter.toLowerCase()) {
                case 'a':
                    state.activeStackLetter = 'A';
                    break;
                case 'b':
                    state.activeStackLetter = 'B';
                default:
                    console.error(
                        `getCloudActiveStack() returned ${activeStackLetter}, which is invalid.`
                    );
            }
        },
        setBoxCountsForStackLetter(
            state: State,
            boxCounts: CloudStackBoxCountsWithLetter
        ) {
            if(!boxCounts || !boxCounts.stackLetter) {
                console.error(`getBoxCountsForStack() returned no active boxes, which is invalid.`)
            }

            switch (boxCounts.stackLetter.toLowerCase()) {
                case 'a':
                    state.aStackBoxCounts = boxCounts.boxCounts;
                    break;
                case 'b':
                    state.bStackBoxCounts = boxCounts.boxCounts;
                    break;
                default:
                    console.error(
                        `Stack letter ${boxCounts.stackLetter} can only be 'a' or 'b'`
                    );
            }
        },
        setConfigForEnvironment(
            state: State,
            configWithEnv: {
                env: CloudEnvironment;
                config: { [key: string]: string | number }[];
            }
        ) {
            state.configByEnvironment[configWithEnv.env] = configWithEnv.config;
        },
        setSecrets(
            state: State,
            secrets: { [key: string]: string | number }[]
        ) {
            state.secrets = secrets;
        }
    };

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
