
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: 'DeleteSetting',
        props: {
            settingToDelete: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                settingConfirm: ''
            };
        },
        computed: {
            confirmHint(): string {
                return `Type Setting Name To Confirm Delete: ${this.settingToDelete}`;
            }
        },
        emits: ['confirmDelete', 'denyDelete', 'closeDelete'],
        methods: {
            confirmDelete() {
                if (this.settingConfirm === this.settingToDelete) {
                    this.$emit('confirmDelete');
                } else {
                    this.$emit('denyDelete');
                }
                this.settingConfirm = '';
            },
            closeDelete() {
                this.$emit('closeDelete');
                this.settingConfirm = '';
            }
        }
    });
