
    import { mapState } from 'vuex';
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'MonitoringDashboard',
        data() {
            return {
                search: ''
            };
        },
        computed: {
            ...mapState({
                aStackBoxCounts: (state: any) => state.cloud.aStackBoxCounts,
                bStackBoxCounts: (state: any) => state.cloud.bStackBoxCounts,
                activeStackLetter: (state: any) => state.cloud.activeStackLetter
            }),
            aStackIsActive: function (): boolean {
                return this.activeStackLetter === 'A';
            },
            bStackIsActive: function (): boolean {
                return this.activeStackLetter === 'B';
            }
        },
        created() {
            this.$store.dispatch('cloud/getActiveStack');
            this.$store.dispatch('cloud/getBoxCountsFromAws', 'a');
            this.$store.dispatch('cloud/getBoxCountsFromAws', 'b');
        },
        methods: {}
    });
