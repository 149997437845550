var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" New Setting ")])]),_c('v-card-text',[_c('v-form',{attrs:{"fast-fail":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-card',{staticClass:"mb-12",attrs:{"height":"auto"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_vm._v(" Key ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"rules":[_vm.isValidKey],"label":"Key","hint":"Setting Key"},model:{value:(_vm.newSettingKey),callback:function ($$v) {_vm.newSettingKey=$$v},expression:"newSettingKey"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"text-h7"},[_vm._v(" Type ")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-select',{attrs:{"label":"Type","items":_vm.configSettingTypes},model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1)],1),_c('v-row',[(_vm.selectedType !== '')?_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"text-h7"},[_vm._v(" Value ")])]):_vm._e(),_c('v-col',{attrs:{"cols":"8"}},[(_vm.selectedType === 'Raw')?_c('v-textarea',{attrs:{"label":"Value"},model:{value:(_vm.newSettingValue),callback:function ($$v) {_vm.newSettingValue=$$v},expression:"newSettingValue"}}):_vm._e(),(
                                                _vm.selectedType === 'String' ||
                                                _vm.selectedType === 'Number'
                                            )?_c('v-text-field',{attrs:{"label":"Value","rules":[_vm.isValidInput]},model:{value:(_vm.newSettingValue),callback:function ($$v) {_vm.newSettingValue=$$v},expression:"newSettingValue"}}):_vm._e(),(_vm.selectedType === 'List')?_c('div',[_vm._l((_vm.listValue),function(item,index){return _c('v-input',{key:index,attrs:{"auto-grow":"","hint":"Setting Value"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.removeItemFromList(
                                                                index
                                                            )}}},[_vm._v(" mdi-minus ")])]},proxy:true}],null,true),model:{value:(_vm.newSetting.value),callback:function ($$v) {_vm.$set(_vm.newSetting, "value", $$v)},expression:"newSetting.value"}},[_vm._v(" "+_vm._s(item)+" ")])}),_c('v-text-field',{attrs:{"auto-grow":"","label":"Add New Item","hint":"Add new item to list"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.addItemToList()}}},[_vm._v(" mdi-plus ")])]},proxy:true}],null,false,1066454599),model:{value:(_vm.listAppendValue),callback:function ($$v) {_vm.listAppendValue=$$v},expression:"listAppendValue"}})],2):(
                                                _vm.selectedType === 'Object'
                                            )?_c('div',[_vm._l((_vm.objValue),function(value,key,index){return _c('v-input',{key:key,attrs:{"auto-grow":"","rules":[_vm.isValidInput],"hint":"Setting Value"},model:{value:(_vm.objValue.value),callback:function ($$v) {_vm.$set(_vm.objValue, "value", $$v)},expression:"objValue.value"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"auto-grow":"","rules":[
                                                            _vm.isValidKey
                                                        ],"label":"Key","hint":"Setting Key"},model:{value:(
                                                            Object.keys(
                                                                _vm.objValue.value
                                                            )[index]
                                                        ),callback:function ($$v) {_vm.$set(Object.keys(
                                                                _vm.objValue.value
                                                            ), index, $$v)},expression:"\n                                                            Object.keys(\n                                                                objValue.value\n                                                            )[index]\n                                                        "}},[_vm._v(" "+_vm._s(key)+" ")])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"auto-grow":"","rules":[
                                                            _vm.isValidInput
                                                        ],"label":"Value","hint":"Setting Value"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.removeItemFromObj(
                                                                        key
                                                                    )}}},[_vm._v(" mdi-minus ")])]},proxy:true}],null,true),model:{value:(
                                                            Object.values(
                                                                _vm.objValue.value
                                                            )[index]
                                                        ),callback:function ($$v) {_vm.$set(Object.values(
                                                                _vm.objValue.value
                                                            ), index, $$v)},expression:"\n                                                            Object.values(\n                                                                objValue.value\n                                                            )[index]\n                                                        "}},[_vm._v(" "+_vm._s(value)+" ")])],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"auto-grow":"","rules":[
                                                            _vm.isValidInput
                                                        ],"label":"New Item Key","hint":"Add new item to object"},model:{value:(
                                                            _vm.objAppendKey
                                                        ),callback:function ($$v) {
                                                            _vm.objAppendKey
                                                        =$$v},expression:"\n                                                            objAppendKey\n                                                        "}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"auto-grow":"","rules":[
                                                            _vm.isValidInput
                                                        ],"label":"New Item Value","hint":"Add new item to object"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.addItemToObj()}}},[_vm._v(" mdi-plus ")])]},proxy:true}]),model:{value:(
                                                            _vm.objAppendValue
                                                        ),callback:function ($$v) {
                                                            _vm.objAppendValue
                                                        =$$v},expression:"\n                                                            objAppendValue\n                                                        "}})],1)],1)],2):_vm._e()],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"type":"submit","color":"green"},on:{"click":_vm.submitRequest}},[_vm._v(" Add Setting ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelRequest}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }