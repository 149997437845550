
    import type { CloudConfigSetting } from '@/store/types';

    import utilities from '@/mixins/utilities';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: 'EditSettingObject',
        props: {
            // eslint-disable-next-line vue/require-default-prop
            settingKey: {
                type: String,
                required: true
            },
            settingValue: {
                type: String,
                required: true
            }
        },
        mixins: [utilities],
        data() {
            return {
                settingToSave: {} as CloudConfigSetting,
                objAppendKey: '',
                objAppendValue: '' as string | number
            };
        },
        created() {
            this.settingToSave.key = this.settingKey;
            this.settingToSave.value = this.parsedValue;
        },
        computed: {
            valueType: function () {
                return this.getTypeFromKeyAndValue(
                    this.settingKey,
                    this.settingValue
                );
            },
            parsedValue: function () {
                switch (this.valueType) {
                    case 'list':
                    case 'object':
                        return JSON.parse(this.settingValue);
                    case 'incompleteObject':
                        return JSON.parse(`{${this.settingValue}}`);
                    default:
                        return this.settingValue;
                }
            },
            serializedValue: function () {
                switch (this.valueType) {
                    case 'list':
                    case 'object':
                        return JSON.stringify(this.settingToSave.value);
                    case 'incompleteObject':
                        let valueString = JSON.stringify(
                            this.settingToSave.value
                        );
                        return valueString.substring(1, valueString.length - 1);
                    default:
                        return this.settingToSave.value;
                }
            }
        },
        watch: {
            settingValue: function () {
                this.settingToSave = {
                    key: this.settingKey,
                    value: this.parsedValue
                };
            }
        },
        emits: ['updateSettingToSave'],
        methods: {
            removeItemFromObj(key: string | number) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [key]: _, ...updatedObj } = this.parsedValue;
                this.settingToSave = {
                    key: this.settingToSave.key,
                    value: { ...updatedObj }
                };
                this.updateParentSettings();
            },
            addItemToObj() {
                let updatedObj = this.parsedValue;

                if (!isNaN(Number(this.objAppendValue))) {
                    this.objAppendValue = +this.objAppendValue;
                }

                updatedObj[this.objAppendKey] = this.objAppendValue;
                this.settingToSave = {
                    key: this.settingToSave.key,
                    value: { ...updatedObj }
                };

                this.updateParentSettings();

                this.objAppendKey = '';
                this.objAppendValue = '';
            },
            updateSettingToSave(key: string, value: any) {
                let newSettings = this.parsedValue;
                newSettings[key] = value;
                this.settingToSave = {
                    key: this.settingToSave.key,
                    value: { ...newSettings }
                };
                this.updateParentSettings();
            },
            updateParentSettings() {
                this.$emit(
                    'updateSettingToSave',
                    this.settingToSave.key,
                    this.settingToSave.value
                );
            }
        }
    });
