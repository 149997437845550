
    import editSettingObject from '@/components/Cloud/editSettingObject.vue';

    import type { CloudConfigSetting } from '@/store/types';

    import utilities from '@/mixins/utilities';
    import { defineComponent, PropType } from 'vue';
    export default defineComponent({
        name: 'EditSetting',
        props: {
            // eslint-disable-next-line vue/require-default-prop
            settingKey: {
                type: String,
                required: true
            },
            settingValue: {
                type: String as PropType<string | number>,
                required: true
            }
        },
        components: {
            editSettingObject
        },
        mixins: [utilities],
        data() {
            return {
                responseFromApi: null,
                originalSetting: {} as CloudConfigSetting,
                settingToSave: {} as CloudConfigSetting,
                listAppendValue: ''
            };
        },
        created() {
            this.originalSetting = {
                key: this.settingKey,
                value: this.parsedValue
            };

            this.settingToSave = {
                key: this.settingKey,
                value: this.parsedValue
            };
        },
        computed: {
            valueType: function () {
                return this.getTypeFromKeyAndValue(
                    this.settingKey,
                    this.settingValue
                );
            },
            parsedValue: function () {
                switch (this.valueType) {
                    case 'list':
                    case 'object':
                        return JSON.parse(String(this.settingValue));
                    case 'incompleteObject':
                        return JSON.parse(`{${this.settingValue}}`);
                    default:
                        return this.settingValue;
                }
            },
            serializedValue: function () {
                switch (this.valueType) {
                    case 'list':
                    case 'object':
                        return JSON.stringify(this.settingToSave.value);
                    case 'incompleteObject':
                        let valueString = JSON.stringify(
                            this.settingToSave.value
                        );
                        return valueString.substring(1, valueString.length - 1);
                    default:
                        return this.settingToSave.value;
                }
            },
            updateSettingPayload: function () {
                return {
                    key: this.settingKey,
                    value: this.serializedValue
                };
            }
        },
        watch: {
            settingValue: function () {
                this.settingToSave = {
                    key: this.settingKey,
                    value: this.parsedValue
                };
            }
        },
        emits: ['updateConfig', 'updateSnackbar', 'closeWindow'],
        methods: {
            saveSetting() {
                this.$store
                    .dispatch('cloud/updateSetting', this.updateSettingPayload)
                    .then((response) => {
                        this.responseFromApi = response;

                        this.originalSetting = { ...this.settingToSave };

                        this.$emit('updateConfig', this.updateSettingPayload);
                        this.$emit(
                            'updateSnackbar',
                            'Setting has been updated.'
                        );
                        this.$emit('closeWindow');
                    })
                    .catch((error) => {
                        this.$emit(
                            'updateSnackbar',
                            'There was an error: ' + error
                        );
                        this.$emit('closeWindow');
                    });
            },
            cancelRequest() {
                this.settingToSave = { ...this.originalSetting };
                this.$emit('closeWindow');
            },
            removeItemFromList(index: number) {
                this.settingToSave.value = this.parsedValue.splice(index, 1);
            },
            addItemToList() {
                let updatedList = this.parsedValue;

                updatedList.push(this.listAppendValue);
                this.settingToSave.value = updatedList;

                this.listAppendValue = '';
            },
            updateSettingToSave(key: string, value: any) {
                this.settingToSave = {
                    key: this.settingToSave.key,
                    value: { ...value }
                };
            }
        }
    });
