
    import { CloudEnvironment, CloudConfigsByEnvironment } from '@/store/types';

    import Constants from '@/constants';
    import { mapState } from 'vuex';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: 'DiffConfig',
        data() {
            return {
                headers: [
                    {
                        text: 'Key',
                        align: 'start',
                        value: 'key'
                    },
                    {
                        text: 'Value',
                        value: 'value'
                    }
                ],
                environments: Constants.cloudEnvironments as CloudEnvironment[],
                selectedEnvironmentA: 'Prod' as CloudEnvironment,
                selectedEnvironmentB: 'Staging' as CloudEnvironment
            };
        },
        created() {
            this.$store.dispatch('cloud/getAllConfigs');
        },
        computed: {
            ...mapState({
                configSettings: (state: any) =>
                    state.cloud.configByEnvironment as CloudConfigsByEnvironment
            })
        },
        methods: {
            getColor(
                envToCompare: CloudEnvironment,
                itemToCompare: { [key: string]: string | number }
            ) {
                const itemValueInOtherEnv = this.configSettings[
                    envToCompare
                ].find((item) => {
                    return item.key === itemToCompare.key;
                });

                if (itemValueInOtherEnv === undefined) {
                    return 'red--text text-darken-4';
                } else if (itemValueInOtherEnv.value !== itemToCompare.value) {
                    return 'yellow--text text-darken-1';
                } else {
                    return '';
                }
            }
        }
    });
