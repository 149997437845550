
    import editSetting from '@/components/Cloud/editSetting.vue';
    import addSetting from '@/components/Cloud/addSetting.vue';
    import deleteSetting from '@/components/Cloud/deleteSetting.vue';

    import { CloudEnvironment, CloudConfigsByEnvironment } from '@/store/types';

    import Constants from '@/constants';
    import utilities from '@/mixins/utilities';
    import { mapState } from 'vuex';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: 'ConfigView',
        components: {
            editSetting,
            addSetting,
            deleteSetting
        },
        data() {
            return {
                CreateDialog: false,
                UpdateDialog: false,
                DeleteDialog: false,
                search: '',
                snackbar: false,
                snackbarText: 'Setting has been updated.',
                headers: [
                    {
                        text: 'Config Setting Key',
                        align: 'start',
                        value: 'key'
                    },
                    {
                        text: 'Config Setting Value',
                        value: 'value'
                    },
                    {
                        text: 'Actions',
                        value: 'actions',
                        sortable: false
                    }
                ],
                environments: Constants.cloudEnvironments as CloudEnvironment[],
                selectedEnvironment: 'Prod' as CloudEnvironment,
                itemToEdit: {} as { [key: string]: string | number },
                indexToEdit: -1,
                itemToDelete: {} as { [key: string]: string | number },
                indexToDelete: -1
            };
        },
        mixins: [utilities],
        created() {
            this.$store.dispatch('cloud/getAllConfigs');
            //this.$store.dispatch('cloud/getSecrets');
        },
        computed: {
            ...mapState({
                configSettings: (state: any) =>
                    state.cloud.configByEnvironment as CloudConfigsByEnvironment
            }),
            shouldAllowDelete(): boolean {
                return (
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.CLOUD_ADMIN) ||
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_ADMIN)
                );
            }
        },
        methods: {
            editItem(item: { [key: string]: string }) {
                this.indexToEdit =
                    this.configSettings[this.selectedEnvironment].indexOf(item);
                this.itemToEdit = { ...item };
                this.UpdateDialog = true;
            },
            deleteItem(item: { [key: string]: string }) {
                this.indexToDelete =
                    this.configSettings[this.selectedEnvironment].indexOf(item);
                this.itemToDelete = { ...item };
                this.DeleteDialog = true;
            },
            confirmDelete() {
                this.configSettings[this.selectedEnvironment].splice(
                    this.indexToDelete,
                    1
                );
                this.closeDelete();
                this.updateSnackbar('Setting has been deleted');
            },
            denyDelete() {
                this.closeDelete();
                this.updateSnackbar('Delete Confirmation Failed');
            },
            closeDelete() {
                this.indexToDelete = 0;
                this.DeleteDialog = false;
            },
            pushToConfig(setting: { key: string; value: string | number }) {
                this.configSettings[this.selectedEnvironment].push(setting);
            },
            updateConfig(setting: { key: string; value: string | number }) {
                const updatedSettings = { ...this.configSettings };
                updatedSettings[this.selectedEnvironment][this.indexToEdit] =
                    setting;

                this.configSettings = { ...updatedSettings };
            },
            updateSnackbar(message: string) {
                this.snackbarText = message;
                this.snackbar = true;
            },
            closeUpdateDialog() {
                this.indexToEdit = 0;
                this.UpdateDialog = false;
            },
            closeCreateDialog() {
                this.CreateDialog = false;
            }
        }
    });
