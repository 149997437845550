var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" Update Setting ")])]),_c('v-card-text',[_c('v-card',{staticClass:"mb-12",attrs:{"height":"auto"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Key","hint":"Setting Key"},model:{value:(_vm.settingToSave.key),callback:function ($$v) {_vm.$set(_vm.settingToSave, "key", $$v)},expression:"settingToSave.key"}})],1),_c('v-col',{attrs:{"cols":"8"}},[(_vm.valueType === 'list')?_c('div',[_vm._v(" Values "),_vm._l((_vm.parsedValue),function(item,index){return _c('v-input',{key:index,attrs:{"auto-grow":"","hint":"Setting Value"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.removeItemFromList(
                                                            index
                                                        )}}},[_vm._v(" mdi-minus ")])]},proxy:true}],null,true),model:{value:(_vm.settingToSave.value),callback:function ($$v) {_vm.$set(_vm.settingToSave, "value", $$v)},expression:"settingToSave.value"}},[_vm._v(" "+_vm._s(item)+" ")])}),_c('v-text-field',{attrs:{"auto-grow":"","label":"Add New Item","hint":"Add new item to list"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.addItemToList()}}},[_vm._v(" mdi-plus ")])]},proxy:true}],null,false,1066454599),model:{value:(_vm.listAppendValue),callback:function ($$v) {_vm.listAppendValue=$$v},expression:"listAppendValue"}})],2):(_vm.valueType === 'object')?_c('div',[_vm._v(" Values "),_c('editSettingObject',{attrs:{"setting-key":_vm.settingKey,"setting-value":JSON.stringify(
                                                    _vm.settingToSave.value
                                                )},on:{"update-setting-to-save":_vm.updateSettingToSave}})],1):(
                                            _vm.valueType === 'incompleteObject'
                                        )?_c('div',[_vm._v(" Values "),_c('editSettingObject',{attrs:{"setting-key":_vm.settingKey,"setting-value":JSON.stringify(
                                                    _vm.settingToSave.value
                                                )},on:{"update-setting-to-save":_vm.updateSettingToSave}})],1):_c('div',[_c('v-text-field',{attrs:{"auto-grow":"","label":"Value","hint":"Setting Value"},model:{value:(_vm.settingToSave.value),callback:function ($$v) {_vm.$set(_vm.settingToSave, "value", $$v)},expression:"settingToSave.value"}})],1)])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"green"},on:{"click":_vm.saveSetting}},[_vm._v(" Update Setting ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelRequest}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }