
    import type { LicenseDetails } from '@/store/types';
    import utilities from '@/mixins/utilities';
    import newEnvForm from '../MhRequestForms/newEnvForm.vue';

    import Constants from '@/constants';
    import { mapState } from 'vuex';
    import moment from 'moment';
    import { defineComponent, PropType } from 'vue';

    interface NewEnvFormComponent {
        getNewEnvData(): any;
        hasErrors(): boolean;
    }
    const today = new Date();

    export default defineComponent({
        props: {
            license: {
                type: Object,
                default: null
            }
        },
        components: {
            newEnvForm
        },
        data() {
            return {
                UpdateDialog: false,
                snackbar: false,
                snackbarText: 'License details have been updated.',
                calmenu: false,
                licenseDone: false,
                errorMessage: '',
                licenseForm: {
                    start: utilities.methods.yearMonthDay(today),
                    // We don't require the user to enter a term limit for demo licenses because demos don't actually have a term limit. But if we
                    // leave it as 0 then the license will be seen as expired after creation. So setting it to 3 to avoid that.
                    termLength: 3,
                    usageLimit: 0,
                    usageMetric: 'users',
                    active: true,
                    awsBudgetForTerm: 0,
                    type: '',
                    licenseId: 0,
                    clientId: this.$route.params.id,
                    expiration: '',
                    product: '',
                    productId: 0
                } as LicenseDetails,
                step: 1,
                formData: {
                    awsRegion: '',
                    newEnvParams: [
                        {
                            clientName: '',
                            dnsName: '',
                            envType: ''
                        }
                    ]
                }
            };
        },
        mixins: [utilities],
        mounted() {
            if (this.license) {
                this.licenseForm = { ...this.license } as LicenseDetails;
            }
        },
        computed: {
            ...mapState({
                accountmanagers: (state: any) =>
                    state.customers.allAccountManagers,
                products: (state: any) => state.aws.products
            }),
            dateFormattedMomentjs(): string {
                return this.licenseForm.start
                    ? moment(this.licenseForm.start).format('MMMM Do YYYY')
                    : '';
            },
            confirmationStepNumber() {
                return this.licenseForm.licenseId === 0 ? 3 : 2;
            }
        },
        methods: {
            initStep2() {
                this.licenseDone = true;
                this.step++;
            },
            goBack() {
                this.step--;
                this.licenseDone = false;
            },
            getProductId() {
                switch (this.licenseForm.product) {
                    case 'engine':
                        return Constants.productId.ENGINE;
                    case 'contentcontroller':
                        return Constants.productId.CONTENT_CONTROLLER;
                    default:
                        return -1;
                }
            },
            updateLicenseForm() {
                // This is to handle the case when the user doesn't interact with the input field
                if (this.licenseForm.type === 'prod') {
                    this.licenseForm.termLength =
                        Constants.licenseTermLength.PROD;
                    if (this.licenseForm.usageMetric === 'users') {
                        this.licenseForm.usageLimit =
                            Constants.licenseUsageLimit.USER;
                    } else {
                        this.licenseForm.usageLimit =
                            this.licenseForm.product === 'engine'
                                ? Constants.licenseUsageLimit
                                      .ENGINE_REGISTRATION
                                : Constants.licenseUsageLimit.CC_REGISTRATION;
                    }
                }
            },
            sendLicenseRequest() {
                this.licenseForm.start = this.yearMonthDay(
                    this.licenseForm.start
                );
                this.licenseForm.expiration = this.yearMonthDay(
                    moment(this.licenseForm.start)
                        .add(this.licenseForm.termLength, 'months')
                        .subtract(1, 'days')
                );

                const dispatchPromise = this.$store.dispatch(
                    this.license
                        ? 'customers/updateLicenseDetail'
                        : 'customers/createLicense',
                    this.licenseForm
                );
                // Only execute these env/mhRequest dispatches if it's a createLicense request
                if (!this.license) {
                    dispatchPromise.then((result) => {
                        const newEnvRecord = {
                            envs: [] as any,
                            product: this.licenseForm.product,
                            productId: this.getProductId(),
                            awsRegion: this.formData.awsRegion,
                            clientId: this.licenseForm.clientId,
                            licenseIds: result
                        };

                        for (const params of this.formData.newEnvParams) {
                            const env = {
                                envType: params.envType,
                                clientName: params.clientName,
                                dnsName: params.dnsName
                            };
                            newEnvRecord.envs.push(env);
                        }

                        const commonPayload = {
                            action: 'newenv',
                            requested_by_slack: localStorage.getItem('slack'),
                            privatebranch: 'master',
                            appversion: 'latest',
                            product: this.licenseForm.product,
                            notes: '',
                            awsRegion: this.formData.awsRegion
                        };

                        const envDetails = {
                            payload: [] as any,
                            requestedby: localStorage.getItem('user')
                        };

                        for (const data of this.formData.newEnvParams) {
                            const payload = {
                                ...commonPayload,
                                clientName: data.clientName,
                                dnsName: data.dnsName,
                                envType: data.envType
                            };
                            envDetails.payload.push(payload);
                        }

                        return this.$store
                            .dispatch(
                                'environments/createNewEnvRecord',
                                newEnvRecord
                            )
                            .then(() => {
                                return this.$store.dispatch(
                                    'mhrequests/sendMhRequest',
                                    envDetails
                                );
                            })
                            .then(() => {
                                this.$store.dispatch(
                                    'customers/getCustomerLicenses',
                                    this.$route.params.id
                                );
                            });
                    });
                }

                dispatchPromise
                    .then(() => {
                        this.$store.dispatch(
                            'customers/getCustomerLicenses',
                            this.$route.params.id
                        );
                    })
                    .then(() => {
                        this.resetLicenseFormData();
                        this.UpdateDialog = false;
                        this.snackbar = true;
                    })
                    .catch((error) => {
                        this.snackbarText = 'There was an error: ' + error;
                        this.snackbar = true;
                    });
            },
            submitForm() {
                const newEnvForm = this.$refs
                    .newEnvForm as any as NewEnvFormComponent;

                if (!newEnvForm.hasErrors()) {
                    const formData = newEnvForm.getNewEnvData();

                    this.formData = formData;
                    this.step = 3;
                } else {
                    this.errorMessage =
                        'Please fix the new env errors before continuing.';
                }
            },
            resetLicenseFormData() {
                if (this.license) {
                    this.licenseForm = { ...this.license } as LicenseDetails;
                } else {
                    this.licenseForm.termLength = 3;
                    this.licenseForm.usageLimit = 0;
                    this.licenseForm.usageMetric = 'users';
                    this.licenseForm.type = '';
                    this.licenseForm.product = '';
                }
                this.errorMessage = '';
                this.UpdateDialog = false;
                this.step = 1;
                this.licenseDone = false;
            },
            saveDateSelection() {
                this.calmenu = false;
            }
        }
    });
