
    import Constants from '@/constants';
    import Vue, { set } from 'vue';
    import { mapState, mapGetters } from 'vuex';
    import utilities from '@/mixins/utilities';

    export default Vue.extend({
        props: {
            // eslint-disable-next-line vue/require-default-prop

            license: {
                type: Object
            }
        },
        data() {
            return {
                newEnvDialog: false,
                snackbar: false,
                snackbarText: 'Your Request Has Been Created.',
                awsRegion: 'us-east-1',
                newEnvParams: [
                    {
                        dnsSubname: '',
                        dnsName: '',
                        clientName: '',
                        envType: ''
                    }
                ]
            };
        },
        mixins: [utilities],
        created() {
            this.$store.dispatch('environments/getAllEnvironments');
            this.populateSubnameAndClientName(this.license);
        },
        computed: {
            ...mapState({
                environments: (state: any) => state.environments.all,
                regions: (state: any) => state.aws.regions,
                envTypes: (state: any) => state.aws.envtypes,
                products: (state: any) => state.aws.products
            }),
            ...mapGetters({
                activeCustomer: 'customers/activeCustomer'
            }),
            isProdEngineLicense(): boolean {
                return (
                    this.license.product === 'engine' &&
                    this.license.type === 'prod'
                );
            },
            shouldAllowAction(): boolean {
                return (
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_SUPPORT) ||
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_ADMIN)
                );
            },
            subDomainValidation(): string | boolean {
                for (const env of this.newEnvParams) {
                    if (
                        env.envType === 'demo' &&
                        !env.dnsSubname.endsWith('-demo')
                    ) {
                        return "POCs must end with '-demo'";
                    }
                }
                return true;
            },
            clientNameValidation(): string | boolean {
                for (const env of this.newEnvParams) {
                    if (env.clientName.length > 13) {
                        return 'clientNames have a limit of 13 characters';
                    }
                }
                return true;
            }
        },
        methods: {
            populateSubnameAndClientName(license: any) {
                this.newEnvParams = [
                    {
                        dnsSubname: this.calcDnsSubname(license.type),
                        dnsName: this.calcDnsName(
                            this.calcDnsSubname(license.type)
                        ),
                        clientName: this.calcClientName(license.type),
                        envType: license.type
                    }
                ];
                if (this.isProdEngineLicense) {
                    this.newEnvParams.push({
                        dnsSubname: this.calcDnsSubname('dev'),
                        dnsName: this.calcDnsName(this.calcDnsSubname('dev')),
                        clientName: this.calcClientName('dev'),
                        envType: 'dev'
                    });
                }
            },
            updateDnsName(index: number) {
                // Update the dnsName property based on the new value of dnsSubname
                this.newEnvParams[index].dnsName = this.calcDnsName(this.newEnvParams[index].dnsSubname);
            },
            calcDnsSubname(envType: string): string {
                return (
                    this.activeCustomer.name.replaceAll(' ', '').toLowerCase() +
                    '-' +
                    envType
                );
            },
            hasErrors(): boolean {
                for (const env of this.newEnvParams) {
                    if (
                        !this.isDnsNameAvailable(env.dnsSubname) ||
                        !this.isClientNameAvailable(env.clientName)
                    ) {
                        return true;
                    }
                }

                return false;
            },
            getNewEnvData(): any {
                let newEnvRecords = {
                    awsRegion: this.awsRegion,
                    newEnvParams: this.newEnvParams
                };
                return newEnvRecords;
            },
            isDnsNameAvailable(subname: string): boolean {
                let fqdnAvailable = true;

                if (subname !== '') {
                    for (const env of this.environments) {
                        if (env.dns === this.calcDnsName(subname)) {
                            fqdnAvailable = false;
                        }
                    }
                } else {
                    fqdnAvailable = false;
                }

                return fqdnAvailable;
            },
            isClientNameAvailable(clientName: string): boolean {
                let clientNameAvailable = true;
                if (clientName !== '' && clientName.length <= 13) {
                    for (const env of this.environments) {
                        if (env.clientname === clientName) {
                            clientNameAvailable = false;
                        }
                    }
                } else {
                    clientNameAvailable = false;
                }

                return clientNameAvailable;
            },
            calcDnsName(subname: string): string {
                let fqdn = '';

                if (this.license.product === 'engine') {
                    fqdn = subname + '.engine.scorm.com';
                }
                if (this.license.product === 'contentcontroller') {
                    fqdn = subname + '.contentcontroller.com';
                }

                return fqdn;
            },
            resetForm() {
                this.newEnvParams = [
                    {
                        dnsSubname: '',
                        dnsName: '',
                        clientName: '',
                        envType: ''
                    }
                ];
            },
            calcClientName(envType: string): string {
                let clientNameSuffix = '';

                switch (envType) {
                    case 'demo':
                        clientNameSuffix = 'dmo';
                        break;
                    case 'dev':
                        clientNameSuffix = 'dev';
                        break;
                    case 'prod':
                        clientNameSuffix = 'prd';
                        break;
                    default:
                        clientNameSuffix = 'unk';
                        break;
                }

                // eslint-disable-next-line no-magic-numbers
                return (
                    this.activeCustomer.name
                        .replaceAll(' ', '')
                        .replaceAll('-', '')
                        .substr(0, 9)
                        .toLowerCase() + clientNameSuffix
                );
            }
        },
    });
