
    import type { CloudConfigSetting } from '@/store/types';

    import utilities from '@/mixins/utilities';
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: 'AddSetting',
        data() {
            return {
                configSettingTypes: [
                    'String',
                    'Number',
                    'List',
                    'Object',
                    'Raw'
                ],
                newSetting: {} as CloudConfigSetting,
                selectedType: '',
                newSettingKey: '',
                newSettingValue: '' as
                    | string
                    | number
                    | Array<string | number>
                    | object,
                listValue: [] as Array<string>,
                listAppendValue: '',
                objValue: {} as { [key: string]: string | number },
                objAppendKey: '',
                objAppendValue: '' as string | number
            };
        },
        mixins: [utilities],
        emits: ['pushToConfig', 'updateSnackbar', 'closeWindow'],
        computed: {
            parsedValue: function () {
                switch (this.selectedType) {
                    case 'Object':
                    case 'List':
                        return JSON.stringify(this.newSettingValue);
                    case 'String':
                    case 'Number':
                    case 'Raw':
                    default:
                        return this.newSettingValue;
                }
            },
            addSettingPayload: function () {
                return {
                    key: this.newSettingKey,
                    value: this.parsedValue
                };
            }
        },
        methods: {
            removeItemFromList(index: number) {
                this.listValue.splice(index, 1);
            },
            addItemToList() {
                let updatedList = this.listValue;

                updatedList.push(this.listAppendValue);
                this.listValue = updatedList;

                this.listAppendValue = '';
            },
            removeItemFromObj(key: string | number) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [key]: _, ...updatedObj } = this.objValue;
                this.objValue = { ...updatedObj };
            },
            addItemToObj() {
                let updatedObj = this.objValue;

                if (!isNaN(Number(this.objAppendValue))) {
                    this.objAppendValue = Number(this.objAppendValue);
                }

                updatedObj[this.objAppendKey] = this.objAppendValue;
                this.objValue = { ...updatedObj };

                this.objAppendKey = '';
                this.objAppendValue = '';
            },
            isValidKey(input: string): boolean | string {
                return this.checkStringNotEmptyAndUsesWordCharacters(input)
            },
            isValidInput(
                input: string | number | Array<string | number> | object
            ): boolean | string {
                switch (this.selectedType) {
                    case 'Number':
                        return !isNaN(Number(input))
                            ? true
                            : 'Entered value is not a valid number';
                    case 'String':
                        return this.checkStringNotEmptyAndUsesWordCharacters(String(input))
                    case 'List':
                        return Array.isArray(input) &&
                            // eslint-disable-next-line no-extra-parens
                            (input as Array<string | number>).every((value) =>
                                this.isValidInput(value)
                            )
                            ? true
                            : 'Value is not a valid list';
                    case 'Object':
                        const isObject =
                            input !== null &&
                            typeof input === 'object' &&
                            !Array.isArray(input);

                        return isObject &&
                            Object.entries(input).every(
                                ([key, value]) =>
                                    this.isValidInput(key) &&
                                    this.isValidInput(value)
                            )
                            ? true
                            : 'Value is not a valid object';
                    case 'Raw':
                    default:
                        return true;
                }
            },
            cancelRequest() {
                this.resetInputs();
                this.$emit('closeWindow');
            },
            submitRequest() {
                this.$store
                    .dispatch('cloud/addSetting', this.addSettingPayload)
                    .then(() => {
                        this.$emit('pushToConfig', this.addSettingPayload);
                        this.$emit(
                            'updateSnackbar',
                            'Setting has been created.'
                        );
                        this.$emit('closeWindow');
                    })
                    .catch((error) => {
                        this.$emit(
                            'updateSnackbar',
                            'There was an error: ' + error
                        );
                        this.$emit('closeWindow');
                    });

                this.resetInputs();
            },
            resetInputs() {
                this.newSetting = {} as CloudConfigSetting;
                this.selectedType = '';
                this.newSettingKey = '';
                this.newSettingValue = '';
                this.listValue = [];
                this.listAppendValue = '';
                this.objValue = {};
                this.objAppendKey = '';
                this.objAppendValue = '';
            }
        }
    });
