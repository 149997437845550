
    import monitoringdashboard from '@/components/Cloud/monitoringdashboard.vue';
    import configview from '@/components/Cloud/configview.vue';
    import diffConfigView from '@/components/Cloud/diffConfigView.vue';

    import Vue from 'vue';

    export default Vue.extend({
        name: 'CloudDashboard',
        components: {
            monitoringdashboard,
            configview,
            diffConfigView
        }
    });
